import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

let defaultLanguage = localStorage.getItem('mineLang')

if (defaultLanguage && defaultLanguage !== '') {
    // user already set his languages
} else {
    defaultLanguage = 'en'
}

const resources = {
    en: {
        translation: {
            objects: {
                users: 'Users',
                loginLog: 'Login Activity',
            },
            common: {
                successfull: 'Successful',
                failed: 'Failed',
                back: 'Back',
                continue: 'Continue',
                filters: 'Filters',
                submit: 'Submit',
                selectOption: 'Select Option',
                okay: 'Okay',
                created: 'Created',
                issued: 'Issued',
                search: 'Search',
                clear: 'Clear',
                status: 'Status',
                all: 'All',
                createdAt: 'Created at',
                updatedAt: 'Updated at',
                download: 'Download',
            },
        },
    },
    // Add more languages as needed
}

i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage, // Default language
    interpolation: {
        escapeValue: false, // React already escapes values
    },
})

export default i18n
