import sidebarSlice from './reduxFunction/sidebarSlice'
import accountAdminSlice from './reduxFunction/accountAdminSlice'
import commonSlice from './reduxFunction/commonSlice'

import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
    reducer: {
        sidebarSlice,
        accountAdminSlice,
        commonSlice,
    },
})
