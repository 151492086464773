import React from 'react'
import { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const ContextApi = createContext()

const ContextProvider = ({ children }) => {
    const [isClickedTab, setIsClickedTab] = useState(false)

    const clickToggle = () => {
        setIsClickedTab(!isClickedTab)
    }

    const [campaignData, setCampaignData] = useState({
        adname: '',
        goal: '',
        adDescription: '',
        category: '',
        subCategory: '',
        creativeType: '',
        headline: '',
        mainText: '',
        audiance: '',
        country: '',
        state: '',
        all: '',
        city: '',
        gender: '',
        hobbies: '',
        occupation: '',
        placeVisited: '',
        startDate: '',
        endDate: '',
        frequency: '',
        sources: '',
        actionButton: '',
        actionHeading: '',
        url: '',
        link: '',
        form: {
            user_name: false,
            city: false,
            state: false,
            age: false,
            gender: false,
            mobileNumber: false,
            address: false,
        },
        mobileNumber: '',
        estimatedBudget: '',
        customBudget: '',
        costPerReach: '',
    })
    const [isEditing, setEditing] = useState(false)
    const [isAudienceUpdate, setAudienceUpdate] = useState(false)

    const value = {
        isClickedTab,
        clickToggle,
        campaignData,
        setCampaignData,
        isEditing,
        setEditing,
        isAudienceUpdate,
        setAudienceUpdate,
    }

    return <ContextApi.Provider value={value}>{children}</ContextApi.Provider>
}

ContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ContextProvider
