import { createSlice } from '@reduxjs/toolkit'

const checkAlreadyMode = localStorage.getItem('testMode')

if (checkAlreadyMode === null) {
    localStorage.setItem('testMode', 1)
}

const initialState = {
    loader: true,
    testMode: Number(localStorage.getItem('testMode')),
    language: localStorage.getItem('mineLang') === 'ar' ? 'ar' : 'en',
    searchKeywordLabel: 'Search',
    searchKeyword: '',
}

export const commonShowData = createSlice({
    name: 'commonShowData',
    initialState,
    reducers: {
        UpdatecommonShowData: (state, newData) => {
            Object.keys(newData.payload).forEach((key) => {
                state[key] = newData.payload[key]
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { UpdatecommonShowData } = commonShowData.actions

export default commonShowData.reducer
