import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    show: Number(localStorage.getItem('screenWidth')) > 360,
    fold: false,
}

export const sidebarShow = createSlice({
    name: 'SidebarShow',
    initialState,
    reducers: {
        setSidebarShow: (state) => {
            state.show = !state.show
        },
        setSidebarFold: (state) => {
            console.log('setSidebarFold')
            state.fold = !state.fold
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSidebarShow, setSidebarFold } = sidebarShow.actions

export default sidebarShow.reducer
